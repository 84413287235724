import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteDataProvider } from '../RouterProvider/RouteDataProvider.provider';
import { StaffDataService } from 'src/app/model/staff-data.service';
import { AlertService } from '../RouterProvider/AlertProvider';
import { ChannelModel, FrequencyModel, OutletModel, BrandCategoryModel, UnitModel, MessageTrigger, ModulesModel, RegionModel, WhiteListModuleOutletModel, UserModel } from 'src/app/model/contract/channel';
import { SettingDataService } from 'src/app/model/setting-data.service';
import { UsersModel } from 'src/app/model/contract/user';
import { UserDataService } from 'src/app/model/user-data.service';
import { UserRoleService } from 'src/app/model/user-role.service';
import { UserRoleModel } from 'src/app/model/contract/userroles';
import { RegionDataService } from 'src/app/model/region-data.service';
import { StateModel } from 'src/app/model/contract/menu-program';

@Injectable({
    providedIn: 'root',
})
export class CommonProvider {
    channelList: ChannelModel[];
    frequencyList: FrequencyModel[];
    reportLists : any[];
    submitted: boolean;
    outletList: OutletModel[];
    whiteListOutlets: WhiteListModuleOutletModel[];
    brandCategoryList: BrandCategoryModel[];
    unitList: UnitModel[];
    messageTrigers: MessageTrigger[];
    modules: ModulesModel[];
    regionList: RegionModel[];
    public states: StateModel[];
    tseUsers: UserModel[];
    m2Users: UserModel[];
    m3Users: UserModel[];

    constructor(private routeDataProvider: RouteDataProvider,
        private staffDataService: StaffDataService,
        private router: Router,
        public alertService: AlertService,
        public settingDataService: SettingDataService,
        private userService: UserDataService,
        private userRoleService: UserRoleService,
        public usersService: RegionDataService,
        private users: UserDataService) {
    }

    getFrequencies(): void {
        this.settingDataService.getAllFrequency()
            .subscribe(
                (result: any) => {
                    this.frequencyList = result.data.frequency;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getStaticReports(): void {
    this.settingDataService.getAllReports()
        .subscribe(
            (result: any) => {
                this.reportLists = result.data.static_reports;
            },
            error => {
                this.alertService.error(error);
            }
      )
  }

    getChannels(): void {
        this.settingDataService.getAllChannel()
            .subscribe(
                (result: any) => {
                    this.channelList = result.data.channels;
                    this.channelList.forEach(
                        c => {
                            c.name = c.channel_desc;
                        }
                    )
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getOutlets(channel_id, geographical_id): void {
        this.settingDataService.getAllOutlets(channel_id, geographical_id)
            .subscribe(
                (result: any) => {
                    this.outletList = result.data.outlets;
                    this.outletList.forEach(
                        c => {
                            c.id = c.outlet_id;
                            c.itemName = c.outlet_name + ' - ' + c.outlet_code;
                            c.checked = false;
                            c.geographical_id = c.region_id;
                        }
                    )
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getUnits(): void {
        this.settingDataService.getAllUnits()
            .subscribe(
                (result: any) => {
                    this.unitList = result.data.unit;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getBrandCategories(): void {
        this.settingDataService.getAllCategories()
            .subscribe(
                (result: any) => {
                    this.brandCategoryList = result.data.category;
                    if(this.brandCategoryList.length > 0) {
                        this.brandCategoryList.forEach(
                            b => {
                                b.name = b.brand_category_name;
                            }
                        )
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getMessageTrigger(): void {
        this.settingDataService.getAllMessageTrigger()
            .subscribe(
                (result: any) => {
                    this.messageTrigers = result.data;
                    if (this.messageTrigers.length > 0) {
                        this.messageTrigers.forEach(
                            m => {
                                if (m.module_id == 1) {
                                    m.moduleTransactionName = '(Loyalty)';
                                }
                                if (m.module_id == 2) {
                                    m.moduleTransactionName = '(Visibilty)';
                                }
                                if (m.module_id == 3) {
                                    m.moduleTransactionName = '(Tertiary Programs)';
                                }
                                if (m.module_id == 4) {
                                    m.moduleTransactionName = '(Content & Learning)';
                                }
                                if (m.module_id == 5) {
                                    m.moduleTransactionName = '(Content & Learning)';
                                }
                                if (m.module_id == 6) {
                                    m.moduleTransactionName = '(Engagement)';
                                }
                                if (m.module_id == 7) {
                                    m.moduleTransactionName = '(Mystery Audit Advocacy)';
                                }
                                if (m.module_id == 10) {
                                    m.moduleTransactionName = '(Scheme)';
                                }
                            }
                        )
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getAllModule(): void {
        this.settingDataService.getAllModules()
            .subscribe(
                (result: any) => {
                    this.modules = result.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    userList: UsersModel[];

    // getUsersList() : void {
    //     this.userService.getAllHubAdminUsers().subscribe(
    //         (result: any) => {
    //             this.userList = result.data;
    //         },
    //         error => {
    //             this.alertService.error(error);
    //         }
    //     )
    // }

    userroles: UserRoleModel[]

    getUserRoles(): void {
        this.userRoleService.getUserRoleList()
            .subscribe(
                (result: any) => {
                    this.userroles = result.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getRegions(): void {
        this.settingDataService.getAllRegion()
            .subscribe(
                (result: any) => {
                    this.regionList = result.data.regions;
                    this.regionList.forEach(ele => {
                        ele.itemName = ele.region_name;
                        ele.checked = false;
                        ele.name = ele.region_name
                    });
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getRegionOutlets(channel_id, region_id): void {
        this.settingDataService.getAllRegionOutlets(channel_id, region_id)
            .subscribe(
                (result: any) => {
                    this.outletList = result.data.outlets;
                    this.outletList.forEach(
                        c => {
                            c.id = c.outlet_id;
                            c.itemName = c.outlet_name + ' - ' + c.outlet_code;
                            c.checked = false;
                        }
                    )
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }

    getWhiteListedOutletForModule(module_id: number): void {
        this.settingDataService.ModuleOutlets(module_id)
            .subscribe(
                (result: any) => {
                    this.whiteListOutlets = result.data;
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


    getStateList(): void {
        this.usersService.getAllStates().subscribe(
            (data) => {
                this.states = data;
                this.states.forEach(
                    s => {
                        s.name = s.state_name;
                        s.id = s.state_code
                    }
                )
            }
        )
    }


    getUsers(): void {
        this.users.getUsers()
            .subscribe(
                (result: any) => {
                    this.tseUsers = result.data.tseusers;
                    if (this.tseUsers.length > 0) {
                        this.tseUsers.forEach(
                            t => {
                                t.name = t.user_detail_name + '-' + t.username;
                                t.id = t.user_id;
                            }
                        )
                    }
                    this.m2Users = result.data.m2users;
                    if (this.m2Users.length > 0) {
                        this.m2Users.forEach(
                            t => {
                                t.name = t.user_detail_name + '-' + t.username;
                                t.id = t.user_id;
                            }
                        )
                    }
                    this.m3Users = result.data.m3users;
                    if (this.m3Users.length > 0) {
                        this.m3Users.forEach(
                            t => {
                                t.name = t.user_detail_name + '-' + t.username;
                                t.id = t.user_id;
                            }
                        )
                    }
                },
                error => {
                    this.alertService.error(error);
                }
            )
    }


}
